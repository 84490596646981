// PerfectScrollbar
.ps__rail-x {
  height: 10px;
  z-index: 2;
}
.ps__rail-y {
  width: 10px;
  z-index: 2;
}
.ps__thumb-x,
.ps__thumb-y {
  border-radius: 0;
}
.ps__rail-x.ps--clicking .ps__thumb-x,
.ps__rail-x:focus>.ps__thumb-x,
.ps__rail-x:hover>.ps__thumb-x {
  height: 6px;
}
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y:hover>.ps__thumb-y {
  width: 6px;
}
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: rgba(#333, .1);
}