// Category Columns
.category-columns {
  @include media-breakpoint-up(sm) {
    column-count: 2;
  }
  @include media-breakpoint-up(md) {
    column-count: 3;
  }
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
  .card {
    border: 0;
    background-color: #f7f7f7;
  }
  .card-body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .list-group-item {
    padding: 0.5rem 1rem;
    border: 0;
    &:not(:hover) {
      background-color: transparent;
    }
  }
}