input[type="number"] {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.input-spinner {
  width: 70px;
  position: relative;
  input[type="number"] {
    padding-right: $input-padding-x + 1.125rem;
  }
  .btn-group-vertical {
    position: absolute;
    top: 1px;
    right: 1px;
    > .btn {
      padding: .094rem 0.25rem;
      font-size: .5rem;
    }
  }
  &.input-spinner-sm {
    width: 55px;
    input[type="number"] {
      padding-right: $input-padding-x-sm + 1rem;
    }
    .btn-group-vertical > .btn {
      padding: .084rem 0.25rem;
      font-size: 6px;
    }
  }
}