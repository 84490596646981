// Webkit Custom Scrollbar
/* width */
::-webkit-scrollbar {
  width: 9px;
  &:horizontal {
    height: 9px;
  }
}
/* Track */
::-webkit-scrollbar-track {
  background: #f2f2f2;
  &:vertical {
    border-left: 1px solid #d9d9d9;
  }
  &:horizontal {
    border-top: 1px solid #d9d9d9;
  }
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  &:vertical {
    border-left: 1px solid #9c9c9c;
  }
  &:horizontal {
    border-top: 1px solid #9c9c9c;
  }
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #919191;
  &:vertical {
    border-left: 1px solid #6a6a6a;
  }
  &:horizontal {
    border-top: 1px solid #6a6a6a;
  }
}