// Header Navbar
header.navbar {
  border-bottom: 1px solid #eee;
  background-color: #fff;
}

// Menu Toggler
.toggle-menu {
  height: 35px;
  width: 35px;
  display: none;
  justify-content: center;
  align-items: center;
  flex: 0 0 35px;
  border-radius: 50%;
  margin-right: 1rem;
  transition: background-color .1s;
  &:hover {
    cursor: pointer;
    background-color: $gray-200;
  }
}
@include media-breakpoint-down(md) {
  .toggle-menu {
    display: flex;
  }
}

// Logo
.navbar-brand {
  > img {
    vertical-align: text-bottom;
    margin-right: 5px;
  }
}

// Search Form
.form-search {
  .form-control {
    border: 0;
    background-color: $light;
    transition: background-color .1s;
    padding-right: 56px;
    &:focus {
      box-shadow: 0 0 2px 0 #ccc;
      background-color: #fff;
    }
  }
  .btn {
    position: absolute;
    right: 0;
    height: $input-height;
    z-index: 3;
    > i.fa {
      vertical-align: text-top;
    }
  }
}
@include media-breakpoint-down(xs) {
  .form-search {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    padding: .5rem 1rem;
    background-color: #fff;
    .form-control {
      padding-left: 56px;
    }
    .btn-search-back {
      left: 0;
      right: auto;
      z-index: 4;
    }
  }
}
@include media-breakpoint-only(md) {
  .form-search {
    width: 30%;
  }
}
@include media-breakpoint-up(lg) {
  .form-search {
    width: 50%;
  }
}

// Navbar Dropdown
.navbar-nav {
  .nav-item.dropdown {
    display: flex;
    align-items: center;
    &.show > .dropdown-toggle {
      background-color: $light;
    }
  }
}

// User Dropdown
.dropdown-user {
  margin-left: 1.5rem;
  > .dropdown-toggle {
    position: relative;
    display: block;;
    width: 35px;
    height: 35px;
    > img {
      position: absolute;
      top: 0;
      right: 0;
      height: 35px;
      border-radius: 50%;
    }
  }
}
@include media-breakpoint-down(xs) {
  .dropdown-user {
    margin-left: .5rem;
  }
}