.table-cart {
  margin-bottom: 2rem;
  td:first-child {
    width: 1%;
    text-align: center;
    vertical-align: middle;
  }
  td:nth-child(2) {
    width: 1%;
    text-align: center;
    > .btn {
      display: none;
    }
  }
  td:last-child {
    width: 1%;
    text-align: center;
  }
  tr:last-child td {
    padding-right: 0;
    padding-left: 0;
  }
  .input-spinner {
    margin-bottom: .5rem;
    > .form-control {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom-style: dotted;
      text-align: center;
    }
    > .btn-group-vertical {
      top: 0;
      right: 0;
      > .btn {
        background-color: #fff;
        border-color: #fff;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .table-cart tr:not(:last-child) td:first-child {
    display: none;
  }
  .table-cart td:nth-child(2) > .btn {
    display: inline-block;
    padding: 0 0.25rem;
  }
}
@include media-breakpoint-up(sm) {
  .table-cart .box-total {
    justify-content: flex-end;
    > h4 {
      margin-right: 1rem;
    }
  }
}